import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';

import { createShoppingList } from 'redux/modules/shopping-lists/actions/create-shopping-list';

import {
  getListsModalLineNumber,
  getShoppingListsCreating,
} from 'redux/modules/shopping-lists/selectors';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { EditNotesAdd } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import CreateListForm from 'components/Lists/CreateList/Form';

const BUTTON_ICON = <EditNotesAdd />;

const CreateList = ({ buttonLabel }) => {
  const dispatch = useWtrDispatch();
  const [showForm, setShowForm] = useState(false);

  const creating = useWtrSelector(state => getShoppingListsCreating(state));
  const lineNumber = useWtrSelector(state => getListsModalLineNumber(state));

  const onSubmit = useCallback(
    values => {
      dispatch(
        createShoppingList({
          lineNumber,
          name: values.listName,
        }),
      ).then(() => setShowForm(false));
    },
    [dispatch, lineNumber],
  );

  const onButtonClick = useCallback(() => setShowForm(true), []);

  return showForm ? (
    <CreateListForm disabled={creating} onSubmit={onSubmit} />
  ) : (
    <Button
      data-testid="create-list-button"
      label={buttonLabel}
      onClick={onButtonClick}
      startIcon={BUTTON_ICON}
      theme="secondary"
      width="fit"
    />
  );
};

CreateList.defaultProps = {
  buttonLabel: '',
};

CreateList.propTypes = {
  buttonLabel: PropTypes.string,
};

export default CreateList;
